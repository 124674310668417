import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormOfOwnershipId,
  useCreateFormOfOwnership,
  useDeleteFormOfOwnership,
  useGetFormsOfOwnership,
  useUpdateFormOfOwnership
} from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { Dictionary, DictionaryItem } from '@controls/DictionaryForm'

export const FormsOfOwnershipPage: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const getFormsOfOwnership = useGetFormsOfOwnership({ onError: defaultErrorHandler })
  const deleteFormOfOwnership = useDeleteFormOfOwnership({ onError: defaultErrorHandler })
  const createFormOfOwnership = useCreateFormOfOwnership({ onError: defaultErrorHandler })
  const updateFormOfOwnership = useUpdateFormOfOwnership({ onError: defaultErrorHandler })

  const loading =
    getFormsOfOwnership.isFetching ||
    deleteFormOfOwnership.isLoading ||
    createFormOfOwnership.isLoading ||
    updateFormOfOwnership.isLoading

  const tableData: ReadonlyArray<DictionaryItem<FormOfOwnershipId>> =
    getFormsOfOwnership.data?.map((row) => ({
      id: row.id,
      eng: row.title.eng,
      est: row.title.est,
      rus: row.title.rus,
      createdAt: row.createdAt
    })) ?? []

  return (
    <div>
      <h1>{t('pages.admin.forms_of_ownership.title')}</h1>
      <Box display="flex" flexDirection="column" rowGap={4}>
        <Dictionary
          title={t('pages.admin.forms_of_ownership.fields.title')}
          loading={loading}
          tableData={tableData}
          onDelete={deleteFormOfOwnership.mutate}
          onInsert={createFormOfOwnership.mutate}
          onUpdate={updateFormOfOwnership.mutate}
        />
      </Box>
      <Box display="flex" columnGap={2} mt={2}>
        <Button disabled={loading} onClick={() => getFormsOfOwnership.refetch()}>
          {t('global.buttons.refresh')}
        </Button>
      </Box>
    </div>
  )
}
